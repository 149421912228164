import { useStoreMap } from 'effector-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import $modal, { closeModal } from '../notifys/modalStore';

import style from './OpenPaymentWindowModal.module.css';

export default function OpenPaymentWindowModal() {
  const { t } = useTranslation();
  const data = useStoreMap({
    store: $modal,
    keys: [],
    fn: (state) => state.data,
  });

  const openWindow = () => {
    if (data?.link) {
      window.open(data?.link);
    }
  };

  return (
    <div className={style.root}>
      <p className={style.content}>{t('paymentWindowModalText')}</p>
      <div className={style.buttonGroup}>
        <Button
          white
          onClick={closeModal}
          className={`${style.button} ${style.white}`}
        >
          {t('close')}
        </Button>
        <Button red onClick={openWindow} className={style.button}>
          {t('open')}
        </Button>
      </div>
    </div>
  );
}
