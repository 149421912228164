export function programIndexConverter(index) {
  const indexNumber = index + 1;
  if (indexNumber > 9) {
    return indexNumber;
  }
  return `0${indexNumber}`;
}


export const getUtmParamsFromLocalStorage = () => {
  const utms = {};
  for (let key in localStorage) {
    if (key.startsWith('utm_')) {
      utms[key] = localStorage.getItem(key);
    }
  }
  return utms;
}

export const boldTextAfterTranslation = (text, wordsIndex) => {
  const wordsArray = text?.split(' ');
  return wordsArray.map((word, i) => {
    const isWordBold = wordsIndex.includes(i);
    return (
      isWordBold
        ? <b key={i}>{word} </b>
        : <span key={i}>{word} </span>
    );
  })
}