import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './GetDemoError.module.css';

export default function GetDemoError() {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <div className={style.content}>
        <div>{t('demoErrorTextPartOne')}</div>
        <div>{t('demoErrorTextPartTwo')}</div>
        <div>{t('demoErrorTextPartThree')}</div>
      </div>
    </div>
  );
}
