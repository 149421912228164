import translation from './translation.json';
import additionalLessonsInfo from './additionalLessonsInfo.json';
import courseProgramm from './courseProgramm.json';
import courseProgrammDetails from './courseProgrammDetails.json';
import reviews from './reviews.json';
import faq from './faq.json';

export default {
  ...translation,
  ...additionalLessonsInfo,
  ...courseProgramm,
  ...courseProgrammDetails,
  ...reviews,
  ...faq,
};