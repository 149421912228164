import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import { fetchRequestCreateInvoice } from '../sections/requests';
import { getUtmParamsFromLocalStorage } from '../helpers';

import style from './AlreadyPaidInvoiceModal.module.css';

export default function AlreadyPaidInvoiceModal({ closeModal }) {
  const { t, i18n } = useTranslation();
  const paySystem = localStorage.getItem('paySystem');
  const name = localStorage.getItem('name');
  const email = localStorage.getItem('email');
  const telegram = localStorage.getItem('telegram');
  const promo = localStorage.getItem('promo');
  const utmsFromLocalStorage = getUtmParamsFromLocalStorage();

  const backToPage = () => {
    closeModal();
    window.location = '/';
  };

  const request = () => {
    if (paySystem && name && email) {
      closeModal();
      fetchRequestCreateInvoice({
        name,
        email,
        language: i18n.resolvedLanguage,
        telegram,
        paySystem,
        promo,
        confirmation: true,
        utmSource: utmsFromLocalStorage?.utm_source,
        utmMedium: utmsFromLocalStorage?.utm_medium,
        utmCampaign: utmsFromLocalStorage?.utm_campaign,
      });
    }
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div>{t('alreadyPaidInvoiceFristPart')}</div>
        <div>{t('alreadyPaidInvoiceSecondPart')}</div>
      </div>
      <div className={style.buttonGroup}>
        <Button
          white
          onClick={request}
          className={`${style.button} ${style.white}`}
        >
          {t('yesModal')}
        </Button>
        <Button red onClick={backToPage} className={style.button}>
          {t('noModal')}
        </Button>
      </div>
    </div>
  );
}
