import React from 'react';
import Button from '../button/Button';
import { closeModal } from '../notifys/modalStore';

export default function ButtonModalClose({ className }) {

  return (
    <Button gray className={className} onClick={closeModal}>
      x
    </Button>
  );
}
