import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './Label.module.css';

export default function Label({ children, type, className, content }) {
  const { t } = useTranslation();
  const defClassName = [
    style.label,
    type === 'video' && style.videoLabel,
    type === 'workshop' && style.shopLabel,
  ]
    .filter((item) => item)
    .join(' ');

  const getContent = () => {
    switch (type) {
      case 'video':
        return t('videoLesson');
      case 'workshop':
        return t('workshop');

      default:
        return '';
    }
  };

  return (
    <div className={`${defClassName} ${className ? className : ''}`}>
      {children || content || getContent()}
    </div>
  );
}
