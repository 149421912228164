import React from 'react';
import LoadingIcon from './LoadingIcon';
import style from './LoadingIconBox.module.css';

export default function LoadingIconBox() {
  return (
    <div className={style.spinner}>
      <LoadingIcon />
    </div>
  );
}
