import modals from '../notifys/modals';
import { openModal } from '../notifys/modalStore';

const mode = 'prod';
// const mode = 'local';

const { ports, BASEURL } = {
  prod: {
    ports: [443],
    BASEURL: `https://api.resonanceholding.com`,
  },
  local: {
    ports: [7001],
    BASEURL: `http://127.0.0.1`,
  }
}[mode];

let index = 0;

const getBaseurl = (isNext) => {
  if (isNext) {
    index++;
    if (index > ports.length - 1) {
      index = 0;
      return false;
    }
  }
  const baseurl = `${BASEURL}:${ports[index]}/api`;
  return baseurl;
};

export async function fetchRequestSubscribe(
  name,
  email,
  telegram,
  language,
  isNext = false,
) {
  try {
    const baseurl = getBaseurl(isNext);
    if (!baseurl) {
      throw { message: 'use all ports' };
    }
    const response = await fetch(`${baseurl}/onlineCourse/subscribe`, {
      method: 'POST',
      body: JSON.stringify({ name, email, telegram, list: 'webinar' }),
    });
    const data = await response.json();
    if (data?.error) {
      if (error?.code === 503) {
        fetchRequestSubscribe(name, email, telegram, language, true);
        return;
      }
      if (data?.error?.message === 'invalidEmail') {
        openModal('invalidEmail');
        return;
      }
      openModal('getDemoError');
      return;
    }

    if (data?.result?.status === 'success') {
      openModal('getDemo');
    }
  } catch (error) {
    openModal('getDemoError');
  }
}

export async function fetchRequestCreateInvoice({
  name,
  email,
  language,
  telegram,
  paySystem,
  promo,
  confirmation,
  isNext = false,
  utmSource,
  utmMedium,
  utmCampaign,
}) {
  try {
    const baseurl = getBaseurl(isNext);
    if (!baseurl) {
      throw { message: 'use all ports' };
    }
    const response = await fetch(`${baseurl}/onlineCourse/createInvoice`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        telegram,
        promo,
        paySystem,
        confirmation,
        utmSource,
        utmMedium,
        utmCampaign,
      }),
    });
    const data = await response.json();
    if (data?.error) {
      if (data?.error?.code === 503) {
        fetchRequestCreateInvoice({
          name,
          email,
          language,
          telegram,
          promo,
          paySystem,
          confirmation,
          isNext: true,
          utmSource,
          utmMedium,
          utmCampaign,
        });
        return;
      }
      if (data?.error?.message === 'invalidEmail') {
        openModal('invalidEmail');
        return;
      }
      openModal('invoiceError');
      return;
    }

    if (data?.result?.status === 'rejected') {
      if (data?.result?.data?.reason === 'alreadyPaid') {
        openModal('alreadyPaidInvoice');
      }
    }
    if (data?.result?.status === 'success') {
      window.location.href = data?.result?.data?.link;
      localStorage.removeItem('name');
      localStorage.removeItem('email');
      localStorage.removeItem('paySystem');
      localStorage.removeItem('promo');
      localStorage.removeItem('telegram');
    }
  } catch (error) {
    openModal('invoiceError');
  }
}

export async function fetchRequestGetPrice(code, isNext = false) {
  try {
    const baseurl = getBaseurl(isNext);
    if (!baseurl) {
      throw { message: 'use all ports' };
    }
    const response = await fetch(`${baseurl}/onlineCourse/getPrice`, {
      method: 'POST',
      body: JSON.stringify({ code }),
    });
    const data = await response.json();
    if (data?.error) {
      if (data?.error?.code === 503) {
        fetchRequestGetPrice(code, true);
        return;
      }
    }
    if (data?.error?.message === 'invalidPromocode') {
      return;
    }
    if (data?.result?.price) {
      return data?.result?.price;
    }
  } catch (err) {
    console.log('ERROR. location: fetchRequestGetPrice, err: ', err);
    openModal('getDemoError');
  }
}

export async function fetchRequestLinkViewed({
  source, medium, campaign, token
}, isNext = false) {
  try {
    const baseurl = getBaseurl(isNext);
    if (!baseurl) {
      throw { message: 'use all ports' };
    }
    const response = await fetch(`${baseurl}/partner/linkViewed`, {
      method: 'POST',
      body: JSON.stringify({
        source, medium, campaign, token
      }),
    });
    const data = await response.json();
    if (data?.error) {
      if (data?.error?.code === 503) {
        fetchRequestLinkViewed({
          source, medium, campaign, token
        }, true);
        return;
      }
    }
  } catch (err) {
    console.log('ERROR. location: fetchRequestLinkViewed, err: ', err);
  }
}

export async function fetchRequestRestore(token, isNext = false) {
  try {
    const baseurl = getBaseurl(isNext);
    if (!baseurl) {
      throw { message: 'use all ports' };
    }
    const response = await fetch(`${baseurl}/auth/restore`, {
      method: 'POST',
      body: JSON.stringify({
        token
      }),
    });
    const data = await response.json();
    if (data?.error) {
      if (data?.error?.code === 503) {
        fetchRequestRestore(code, true);
        return;
      }
    }
    if (data?.result?.result) {
      return data?.result?.result;
    } else if (data?.result) {
      return data?.result?.result;
    }
  } catch (err) {
    console.log('ERROR. location: fetchRequestRestore, err: ', err);
  }
}
