import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './GetDemoModal.module.css';

export default function GetDemoModal() {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <p className={style.content}>{t('sendLink')}</p>
    </div>
  );
}
