import { createStore, createEvent } from 'effector';
import modals from './modals/modals';

const initialStore = {
  isActive: false,
  type: null,
  data: {},
  isLoading: false,
};

export const openModal = createEvent();
export const closeModal = createEvent();

export const setIsLoading = createEvent();

const $modal = createStore(initialStore)
  .on(openModal, (store, modalId) => {
    if (!modalId) {
      return { ...store };
    }
    const neededModal = modals[modalId];

    return {
      ...store,
      isActive: true,
      data: {},
      type: neededModal?.type,
    };
  })
  .on(closeModal, (store) => ({
    ...store,
    isActive: false,
    type: null,
    data: {},
  }))
  .on(setIsLoading, (store, isLoading) => ({
    ...store,
    isLoading,
  }));

export default $modal;
