import React from 'react';

import styles from './Button.module.css';

export default function Button({
  children,
  onClick = () => { },
  className,
  type = 'button',
  red,
  white,
  basic,
  gray,
  slider,
  style,
  id,
}) {
  const defaultClassNames = [
    styles.animation,
    styles.reset,
    (!basic || !gray || !slider) && styles.button,
    // !gray && style.gray,
    // !slider && style.gray,
    red && styles.red,
    white && styles.white,
    basic && styles.basic,
    gray && styles.gray,
    slider && styles.slider,
  ];

  const defaultClassName = defaultClassNames
    .filter((item) => item).join(' ');

  const onClickWrapper = (e) => {
    onClick(e, { id });
  }

  return (
    <button
      style={style}
      type={type}
      className={`${defaultClassName} ${className ? className : ''}`}
      onClick={onClickWrapper}
    >
      {children}
    </button>
  );
}
