import React from 'react';

import { closeModal } from '../modalStore';
import lessonsModals from './lessonModals';

import TestModal from './TestModal';
import GetDemoModal from '../../getDemoModal';
import OpenPaymentWindowModal from '../../openPaymentWindowModal';
import GetDemoError from '../../getDemoError';
import InvoiceErrorModal from '../../invoiceErrorModal';
import InvalidEmailModal from '../../invalidEmailModal';
import AlreadyPaidInvoiceModal from '../../alreadyPaidInvoiceModal';

const modals = {
  modal1: {
    type: 'modal1',
    content: <TestModal />,
    closeModal: closeModal,
  },

  ...lessonsModals,

  getDemo: {
    type: 'getDemo',
    content: <GetDemoModal />,
    closeModal: closeModal,
  },
  openPaymentWindow: {
    type: 'openPaymentWindow',
    content: <OpenPaymentWindowModal closeModal={closeModal} />,
    closeModal: closeModal,
  },
  getDemoError: {
    type: 'getDemoError',
    content: <GetDemoError closeModal={closeModal} />,
    closeModal: closeModal,
  },
  invoiceError: {
    type: 'invoiceError',
    content: <InvoiceErrorModal closeModal={closeModal} />,
    closeModal: closeModal,
  },
  invalidEmail: {
    type: 'invalidEmail',
    content: <InvalidEmailModal closeModal={closeModal} />,
    closeModal: closeModal,
  },
  alreadyPaidInvoice: {
    type: 'alreadyPaidInvoice',
    content: <AlreadyPaidInvoiceModal closeModal={closeModal} />,
    closeModal: closeModal,
  },

  get(key) {
    if (key === null) {
      return;
    }
    if (this[key] !== undefined) return this[key];
    else {
      console.warn(`modals type = '${key}' do not find`);
      // console.trace();
      closeModal();
      return;
    }
  },
};

export const modalsWithButtonClose = [
  'modal1',
  'getDemo',
  'openPaymentWindow',
  'getDemoError',
];

export default modals;
