import React from 'react';

export default function TestModal() {
  return (
    <div style={{ width: '500px', height: '500px', color: 'white' }}>
      <button>1</button>
      TestModal
      <button>2</button>
      <button>3</button>
    </div>
  );
}
