import { useStoreMap } from 'effector-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../button';
import $modal from '../notifys/modalStore';

import style from './InvalidEmailModal.module.css';

export default function InvalidEmailModal({ closeModal }) {
  const { t } = useTranslation();
  const data = useStoreMap({
    store: $modal,
    keys: [],
    fn: (state) => state.data,
  });

  const backToPage = () => {
    closeModal();
    window.location = '/';
  };

  const getContent = (content) => {
    switch (content) {
      case 'invalidEmail':
        return t('notCorrectEmail');
      case 'blacklistedEmail':
        return t('blackListedEmail');

      default:
        return t('notCorrectEmail');
    }
  };

  return (
    <div className={style.root}>
      <div className={style.content}>{getContent(data.error)}</div>
      <Button red onClick={backToPage} className={style.button}>
        {t('change')}
      </Button>
    </div>
  );
}
