import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import './index.css';

import './i18n';
import LoadingIconBox from './components/loading/LoadingIconBox';
import { fetchRequestLinkViewed, fetchRequestRestore } from './components/sections/requests';

const App = React.lazy(() => import('./App'));
const CheckoutPage = React.lazy(() => import('./pages/checkoutPage'));


const setUtmParamsToLocalStorage = (token) => {
  const utmParams = new URLSearchParams(window.location.search);
  const utms = {};
  utmParams.forEach((value, key) => {
    if (key.startsWith('utm_')) {
      utms[key] = value; // извлекаем значения utm-параметров
      localStorage.setItem(key, value); // Сохраняем значение localStorage
    }
  });
  if (Object.keys(utms).length > 0)
    fetchRequestLinkViewed({
      source: utms.utm_source,
      medium: utms.utm_medium,
      campaign: utms.utm_campaign,
      token,
    });
}

const tokenFromStore = localStorage.getItem('token');
fetchRequestRestore(tokenFromStore)
  .then((data) => {
    const { token } = data;
    localStorage.setItem('token', token);
    setUtmParamsToLocalStorage(token);
  });

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/ru',
    element: <App />,
  },
  {
    path: '/checkout',
    element: <CheckoutPage />,
  },
  {
    path: '/ru/checkout',
    element: <CheckoutPage />,
  },
  {
    path: '/*',
    element: <Navigate replace to="/" />,
  },
  {
    path: '/ru/*',
    element: <Navigate replace to="/ru" />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingIconBox />}>
      <RouterProvider router={router} />
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
