export default [
  {
    id: 'lesson_1',
    details: [
      { id: 'lesson_1_1' },
      { id: 'lesson_1_2' },
      { id: 'lesson_1_3' },
    ],
  },
  {
    id: 'lesson_2',
    details: [
      { id: 'lesson_2_1' },
      { id: 'lesson_2_2' },
      { id: 'lesson_2_3' },
      { id: 'lesson_2_4' },
    ],
  },
  {
    id: 'lesson_3',
    details: [
      { id: 'lesson_3_1' },
      { id: 'lesson_3_2' },
      { id: 'lesson_3_3' },
    ],
  },
  {
    id: 'lesson_4',
    details: [
      { id: 'lesson_4_1' },
      { id: 'lesson_4_2' },
    ],
  },
  {
    id: 'lesson_5',
    details: [
      { id: 'lesson_5_1' },
      { id: 'lesson_5_2' },
      { id: 'lesson_5_3' },
    ],
  },
  {
    id: 'lesson_6_1',
  },
  {
    id: 'lesson_6_2',
    details: [
      { id: 'lesson_6_2_1' },
    ],
  },
  {
    id: 'lesson_6_3',
  },
  {
    id: 'lesson_7',
    details: [
      { id: 'lesson_7_1' },
      { id: 'lesson_7_2' },
      { id: 'lesson_7_3' },
      { id: 'lesson_7_4' },
    ],
  },
  {
    id: 'lesson_8_1',
  },
  {
    id: 'lesson_8_2',
  },
  {
    id: 'lesson_9',
    details: [
      { id: 'lesson_9_1' },
      { id: 'lesson_9_2' },
      { id: 'lesson_9_3' },
    ],
  },
  {
    id: 'lesson_10_1',
  },
  {
    id: 'lesson_10_2',
  },
  {
    id: 'lesson_11',
  },
];