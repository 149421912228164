import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import translationUA from './locales/ua/translation.json';
import translationRU from './locales/ru/index.js';

const availableLanguages = [
  // 'ua',
  'ru'
];

const resources = {
  // ua: {
  //   translation: translationUA,
  // },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'ru',
    fallbackLng: 'ru',
    whitelist: availableLanguages,
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
