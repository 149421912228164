import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonModalClose from '../buttonModalClose';
import { programIndexConverter } from '../helpers';
import Label from '../label';

import style from './LessonModal.module.css';

import lessonsData from '../../data/lessonsData';

function LessonModal({ id }) {
  const { t } = useTranslation();

  const detailInfo = lessonsData.find((item) => item.id === id);

  const lessonNumber = id.split('_')[1];

  const listItems = detailInfo?.details?.map(({ id: detailsId }, i) => {
    return (
      <li key={i} className={style.listItem}>
        <div className={style.marker}>
          {`/${programIndexConverter(i)}`}
        </div>
        <span className={style.listItemContent}>
          {t(detailsId)}
        </span>
      </li>
    );
  });


  return (
    <div className={style.wrapper}>
      <div className={style.root}>
        <ButtonModalClose className={'close-modal-button'} />
        <div className={style.index}>{lessonNumber}</div>
        <div className={style.headerWrapper}>
          <Label type={'video'} className={style.label} />
          <h2 className={style.header}>
            {t(id)}
          </h2>
        </div>
        <ul className={style.list}>
          {listItems}
        </ul>
      </div>
    </div>
  );
}

export default LessonModal;
