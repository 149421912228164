import React from 'react';

export default function LoadingIcon() {
  return (
    <>
      <div className="el1-wrap">
        <svg
          className="el1"
          fill="none"
          viewBox="0 0 50 50"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_17)">
            <path
              clipRule="evenodd"
              d="M41.2002 34.9275C43.031 31.9398 44 28.504 44 25H50C50 29.6106 48.725 34.1313 46.316 38.0625C43.907 41.9936 40.4578 45.182 36.3498 47.2752C32.2417 49.3683 27.6349 50.2847 23.0385 49.9229C18.4422 49.5612 14.0354 47.9354 10.3054 45.2254L13.8321 40.3713C16.6669 42.4309 20.0161 43.6665 23.5093 43.9414C27.0025 44.2164 30.5037 43.5199 33.6258 41.9291C36.7479 40.3383 39.3693 37.9151 41.2002 34.9275Z"
              fill="#017DFB"
              fillRule="evenodd"
            />
            <path
              clipRule="evenodd"
              d="M8.79983 15.0725C6.96898 18.0602 6 21.496 6 25H-3.8147e-06C-3.8147e-06 20.3894 1.27498 15.8687 3.68399 11.9375C6.093 8.00639 9.5422 4.81799 13.6502 2.72484C17.7583 0.63169 22.3651 -0.284671 26.9615 0.0770693C31.5578 0.438808 35.9646 2.06456 39.6946 4.77457L36.1679 9.62868C33.3331 7.56906 29.9839 6.33349 26.4907 6.05857C22.9975 5.78365 19.4963 6.48008 16.3742 8.07088C13.2521 9.66167 10.6307 12.0849 8.79983 15.0725Z"
              fill="#017DFB"
              fillRule="evenodd"
            />
          </g>
        </svg>
      </div>

      <div className="el2-wrap">
        <svg
          className="el2"
          fill="none"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            d="M9.80548 23.5853C11.2049 24.7166 12.8945 25.4453 14.6875 25.6883L13.882 31.6339C11.005 31.2442 8.2887 30.0745 6.0335 28.2514C3.77806 26.4281 2.07027 24.0211 1.10305 21.2936C0.135738 18.5658 -0.0522432 15.626 0.561925 12.8001C1.17608 9.97435 2.56752 7.37664 4.57906 5.29348L8.89525 9.46127C7.65527 10.7454 6.80152 12.3422 6.42504 14.0744C6.04858 15.8066 6.16312 17.6106 6.75801 19.2882C7.35301 20.9661 8.40628 22.4542 9.80548 23.5853Z"
            fill="#BABBBC"
            fillRule="evenodd"
          />
          <path
            clipRule="evenodd"
            d="M22.1945 8.41469C20.7951 7.2834 19.1055 6.55465 17.3125 6.31174L18.118 0.366058C20.995 0.755825 23.7113 1.92555 25.9665 3.74863C28.2219 5.57189 29.9297 7.97894 30.8969 10.7064C31.8643 13.4342 32.0522 16.374 31.4381 19.1999C30.8239 22.0257 29.4325 24.6234 27.4209 26.7065L23.1047 22.5387C24.3447 21.2546 25.1985 19.6578 25.575 17.9256C25.9514 16.1934 25.8369 14.3894 25.242 12.7118C24.647 11.0339 23.5937 9.54579 22.1945 8.41469Z"
            fill="#BABBBC"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </>
  );
}
