import React from 'react';
import LessonModal from '../../lessonModal';
import { closeModal } from '../modalStore';
import lessonsData from '../../../data/lessonsData';

const lessonsModals = {};

for (const { id: lessonKey } of lessonsData) {
  lessonsModals[lessonKey] = {
    type: lessonKey,
    content: <LessonModal id={lessonKey} />,
    closeModal: closeModal,
  }
}

export default lessonsModals;